import { Form } from "react-bootstrap";
import uploadToFirebase from "../../../helpers/FirebaseUpload";
import { ref } from "@firebase/storage";
import { storage } from "../../../firebase";

function FileField({
  field,
  values,
  touched,
  handleBlur,
  setFieldValue,
  errors,
  searchParamsObj,
}) {
  async function handleUpload(e) {
    if (e.target?.files.length > 0) {
      const folder = "DocumentsUnderDynamicForm";
      const value = await uploadToFirebase(
        e.target.files[0],
        folder,
        e.target.files[0]?.name
      );
      setFieldValue(field?._id, value);
    } else {
      setFieldValue(field?._id, "");
    }
  }

  const getOgNameFromFirebase = (file) => {
    const storageRef = ref(storage, file);
    return storageRef.name;
  };

  const isBold = field?.highlight;
  return (
    <Form.Group>
      <Form.Label style={isBold ? { fontWeight: "500" } : {}}>
        {field?.title}
      </Form.Label>
      <Form.Control
        type="file"
        name={field?._id}
        // value={values[field?.title]}
        onChange={(e) => handleUpload(e)}
        onBlur={handleBlur}
        disabled={searchParamsObj[field?.title]}
      />

      {values[field?._id] && (
        <p style={{ fontSize: "13px" }}>
          <a href={values[field?._id]} target="_blank">
            {getOgNameFromFirebase(values[field?._id])}
          </a>
        </p>
      )}
      {touched[field?._id] && errors[field?._id] && (
        <div className="errorMessage">{errors[field?._id]}</div>
      )}
    </Form.Group>
  );
}

export default FileField;
