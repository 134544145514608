export const REACT_APP_GRAPHQL_PG = "https://rinoj-81.workspace-b.techwarelab.com/graphql";
export const LOGIN_URI = "https://xlri-live.techlabz.in/xlri-auth/accessToken";
export const LIVE_URL = "https://admissions.xlri.ac.in/login";
export const CAPTCHA_SITE_KEY = "6Ld4_6IkAAAAABuju-KWh8zB8tvubqTM_2wqTw_e";
export const AUTHENTICATIONTOKEN_LIVE = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiWExSSV9MSVZFXzIwMjMiLCJpc3MiOiJXc0N0OVJwQXpTQ1NBNVg4WWpSRkg2azhzcHRDQVFlcSIsImlhdCI6MTY3OTkxMDU2OCwiZXhwIjoxNjc5OTUzNzY4fQ.T7FcvUUT8629VXCqCJqdSnXqgLOzrd-zHT3Dp7keYbs"
export const SECRET = "qwerty"


//export const REACT_APP_GRAPHQL_PG = "https://api-gateway-xlri.techlabz.in/xlri-admission/graphql";
//export const LOGIN_URI = "https://api-gateway-xlri.techlabz.in/xlri-auth/accessToken";
//export const LIVE_URL = "https://admissions.xlri.ac.in/login";
//export const CAPTCHA_SITE_KEY = "6Ld4_6IkAAAAABuju-KWh8zB8tvubqTM_2wqTw_e";
export const AUTHENTICATIONTOKEN_DEV  = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiWExSSV9MSVZFXzIwMjMiLCJpc3MiOiJXc0N0OVJwQXpTQ1NBNVg4WWpSRkg2azhzcHRDQVFlcSIsImlhdCI6MTY3OTkxMDU2OCwiZXhwIjoxNjc5OTUzNzY4fQ.T7FcvUUT8629VXCqCJqdSnXqgLOzrd-zHT3Dp7keYbs"




