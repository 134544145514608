export const setToken = (data) => {
    try{
        if(data && data.token && data.uid) {
            localStorage.setItem('auth-token', data.token)
            localStorage.setItem('uuid', data.uid)
        }
        else{
            localStorage.removeItem('auth-token')
            localStorage.removeItem('uuid')
        }
    }catch(error){
        console.log(error);
    }
}

export const getToken = () => {
    try{
        let token = localStorage.getItem('auth-token')
        let uid = localStorage.getItem('uuid')
        if (token && uid) {
            return { uid, token }
        }
        return null;
    }catch(error){
        return null;
    }
}


export const convertDate = (str) => {
    const isoString = str;
    const dateParts = isoString.split("T")[0].split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  
    const formattedDate = `${
      monthNames[parseInt(dateParts[1], 10) - 1]
    } ${parseInt(dateParts[2], 10)}`;
  
    return formattedDate;
  };