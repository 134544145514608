import React from "react";
import { Container } from "react-bootstrap";
import GalleryCss from "./Gallery.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const GalleryTwo = () => {
  const optionsTwo = {
    dots: false,
    margin: 18,
    loop: true,
    nav: false,
    autoplay: true,
    autoplayTimeout: 2000,
    responsiveClass: true,
    responsive: {
      300: {
        items: 1,
        nav: true,
      },
      600: {
        items: 1,
        nav: true,
      },
      1000: {
        items: 3,
        nav: true,
        loop: true,
      },
    },
  };
  return (
    <div>
      <div className="GalleryMainCommon">
        <div className={GalleryCss.GalleryMainSec}>
          <Container>
           
            <div className={GalleryCss.GallerySecOne}>
              <OwlCarousel {...optionsTwo}>
                <div className={GalleryCss.SliderBox}>
                  <img src="/assets/images/gallery-img-1.webp" alt="" />
                </div>

                <div className={GalleryCss.SliderBox}>
                  <img src="/assets/images/gallery-img-2.webp" alt="" />
                </div>

                <div className={GalleryCss.SliderBox}>
                  <img src="/assets/images/gallery-img-3.webp" alt="" />
                </div>
                <div className={GalleryCss.SliderBox}>
                  <img src="/assets/images/gallery-img-2.webp" alt="" />
                </div>
              </OwlCarousel>
            </div>
            <div className={GalleryCss.GallerySecTwo}>
              <OwlCarousel {...optionsTwo}>
                <div className={GalleryCss.SliderBox}>
                  <img src="/assets/images/gallery-img-1.webp" alt="" />
                </div>

                <div className={GalleryCss.SliderBox}>
                  <img src="/assets/images/gallery-img-5.webp" alt="" />
                </div>

                <div className={GalleryCss.SliderBox}>
                  <img src="/assets/images/gallery-img-2.webp" alt="" />
                </div>
                <div className={GalleryCss.SliderBox}>
                  <img src="/assets/images/gallery-img-5.webp" alt="" />
                </div>
              </OwlCarousel>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default GalleryTwo;
