import { Form } from "react-bootstrap";

function CheckboxField({
  field,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  searchParamsObj,
}) {
  let selectedValues = [];

  if (Array.isArray(values[field?._id])) {
    selectedValues = values[field?._id];
  } else if (typeof values[field?._id] === "string") {
    selectedValues = values[field?._id].split(",");
  }

  const isBold = field?.highlight;

  return (
    <Form.Group>
      <Form.Label style={isBold ? { fontWeight: "500" } : {}}>
        <span dangerouslySetInnerHTML={{ __html: field?.title }} />
      </Form.Label>
      {field?.options?.map((option) => (
        <Form.Check
          id={option}
          key={option}
          type={field?.type}
          name={field?._id}
          value={option}
          onChange={handleChange}
          onBlur={handleBlur}
          label={<span dangerouslySetInnerHTML={{ __html: option }} />}
          checked={selectedValues.includes(option)}
          disabled={searchParamsObj[field?.title]}
        />
      ))}
      {touched[field?._id] && errors[field?._id] && (
        <div className="errorMessage">{errors[field?._id]}</div>
      )}
    </Form.Group>
  );
}

export default CheckboxField;
