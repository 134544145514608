import { Formik } from "formik";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

function DetailModal(props) {
  function onSubmit(data) {
    props.makePayment(
      props.details.amount,
      props.details.orderId,
      props.details.custId,
      data.name,
      data.email,
      props.details.respId,
      data.phone
    );
  }
  return (
    <div>
      <Modal
        className="AutoPopupMain"
        show={props.show}
        backdrop="static"
        onHide={props.onHide}
        dialogClassName="modal-group-auto modal-assignment"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: "",
              email: "",
              phone: "",
            }}
            onSubmit={onSubmit}
          >
            {({
              values,
              touched,
              errors,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldError,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.name && touched.name ? "inputError" : ""
                        }
                        placeholder="Name"
                      />
                      {errors.name && touched.name ? (
                        <div>{errors.name}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-sm-12">
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.email && touched.email ? "inputError" : ""
                        }
                        type="text"
                        placeholder="Email Address"
                      />
                      {errors.email && touched.email ? (
                        <div>{errors.email}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-sm-12">
                    <Form.Group className="mb-3">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="number"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.phone && touched.phone ? "inputError" : ""
                        }
                        placeholder="Contact number"
                      />
                      {errors.phone && touched.phone ? (
                        <div>{errors.phone}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-sm-12">
                    <Button
                      type="submit"
                      className="applyBtn"
                      variant="primary"
                    >
                      Pay
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DetailModal;
