import React, { useState } from "react";
import GalleryCss from "./Gallery.module.scss";
import { Container, Tab, Nav } from "react-bootstrap";
import Gallery from "./Gallery";
import GalleryTwo from "./GalleryTwo";

const TabGallery = () => {
  const [currentTab, setCurrentTab] = useState(1);
  // const key = 1;
  const handleSelect = (eventKey) => {
    console.log(parseInt(eventKey) + 1);
    setCurrentTab(eventKey);
    
  };

 

  return (
    <div>
      <Container>
        <div className="slider1MainAll">
          <Tab.Container
            defaultActiveKey="1"
            activeKey={currentTab}
            onSelect={(key) => setCurrentTab(key)}
          >
            <div className={GalleryCss.GalleryMainTab}>
              <Nav bsStyle="pills" stacked>
                <Nav.Link className={currentTab === '1' ? 'leftButtonActive' : 'leftButton'} eventKey="1">
                  {" "}
                  Jamshedpur
                </Nav.Link>
                <Nav.Link className={currentTab === '2' ? 'leftButtonActive ActiveLeft' : 'leftButton ActiveLeft'} eventKey="2">
                  Delhi
                </Nav.Link>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="1">
              <GalleryTwo handleSelect={handleSelect} />
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="2">
              <Gallery handleSelect={handleSelect} />
               
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </div>
  );
};

export default TabGallery;
