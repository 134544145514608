import React, { useEffect, useState } from "react";
import { FullLoader } from "../loader";

const BillDeskComponent = ({data}) => {
  const [loading,setLoading] = useState(false)
  // useEffect(() => {
  //   const loadBillDeskSdk = () => {
  //     const script = document.createElement("script");
  //     script.src =
  //       "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js";
  //     script.async = true;
  //     script.noModule = true;
  //     script.type = "text/javascript";

  //     script.onload = () => {
  //       // Initialize the BillDesk SDK after the script has loaded
  //       window.loadBillDeskSdk({
  //         flowConfig: {
  //           ...data,
  //           // returnUrl: "https://xlis.xlri.ac.in/",
  //           crossButtonHandling: "Y",
  //           childWindow: false,
  //         },
  //         flowType: "payments",
  //         responseHandler: (txn) => {
  //           if (txn.response) {
  //             // Do Something..
  //           }
  //         },
  //       });
  //     };

  //     script.onerror = (error) => {
  //       console.error("Failed to load BillDesk SDK:", error);
  //     };

  //     // Append the script to the document's head
  //     document.head.appendChild(script);
  //   };

  //   loadBillDeskSdk();
  // }, []);

  useEffect(() => {
    const loadBillDeskSdk = async () => {
      
      try {
        setLoading(true)
        // Dynamically import the BillDesk SDK script based on browser support
        const sdkModule = await import(
          "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js"
        );
        const sdkNomodule = await import(
          "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js"
        );
          setLoading(false)
        // Initialize the BillDesk SDK after the script has loaded
        if (sdkModule) {
          window.loadBillDeskSdk({
            flowConfig: {
              ...data,
              // returnUrl: "https://xlis.xlri.ac.in/",
              crossButtonHandling: "Y",
              childWindow: false,
            },
            flowType: "payments",
            responseHandler: (txn) => {
              if (txn.response) {
                // Do Something..
              }
            },
          });
        }
        setLoading(false)
      } catch (error) {
        console.error("Failed to load BillDesk SDK:", error);
        setLoading(false)
      }
    };

    loadBillDeskSdk();


  }, []);

  return (
    <div>
      {loading && <FullLoader/>}
      <script
        type="module"
        src="https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js"
      ></script>
      <script
        noModule=""
        src="https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js"
      ></script>
    </div>
  );
};

export default BillDeskComponent;
