import React, { useRef } from "react";
import styles from "../forms.module.scss";

function ImageRadio({ imageOptions, handleChange, currentValue }) {
  const quantityRef = useRef();
  function handleClick() {
    if (imageOptions?.isQuantity) {
      handleChange(`${quantityRef.current.value}-${imageOptions?.value}`);
    } else {
      handleChange(imageOptions?.value);
    }
  }

  const inputedValue = imageOptions?.isQuantity
    ? currentValue.split("-")[1]
    : currentValue;
  return (
    <div
      className={`${
        imageOptions?.value === inputedValue ? styles.activeRadio : ""
      } ${styles.radioImageWrapper}`}
      onClick={handleClick}
    >
      <img src={imageOptions?.image_url} alt="img" />
      <p>{imageOptions?.value}</p>
      {imageOptions?.isQuantity && (
        <input
          type="number"
          className={styles.countInput}
          defaultValue={1}
          ref={quantityRef}
          onChange={handleClick}
          min={1}
        />
      )}
    </div>
  );
}

export default ImageRadio;
