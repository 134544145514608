import React from "react";
import { Container } from "react-bootstrap";
import ContactCss from "./Contact.module.scss";
const Contact = () => {
  return (
    <div>
      <div className={ContactCss.ContactMainSec}>
        <Container>
          <div className={ContactCss.ContactSec}>
            <div className={ContactCss.LocationSec}>
              <div className={ContactCss.ContentInside}>
                <img src="/assets/images/contact-img-1.svg" alt="" />
                <div className={ContactCss.Text}>
                  <h1>XLRI</h1>
                  <p>C. H. Area (East) <br/>Jamshedpur Jharkhand</p>
                </div>
              </div>
            </div>
            <span></span>
            <div className={ContactCss.CalSec}>
              <div className={ContactCss.ContentInside}>
                <img src="/assets/images/contact-img-2.svg" alt="" />
                <div className={ContactCss.Text}>
                  <h1>SPEAK TO US</h1>
                  <p className={ContactCss.Click}>+91 657 665 3203 <br/>+91 657 665 3204</p>
                  
                  <h2>Fax:</h2>
                  <p className={ContactCss.Click}>+91 657 222 7814</p>
                  
                </div>
              </div>
            </div>
            <span></span>
            <div className={ContactCss.EmailSec}>
              <div className={ContactCss.ContentInside}>
                <img src="/assets/images/contact-img-3.svg" alt="" />
                <div className={ContactCss.Text}>
                  <h1>EMAIL US AT</h1>
                  <p className={ContactCss.Click}>admis@xlri.ac.in</p>
                </div>
              </div>
            </div>
            <span></span>
            <div className={ContactCss.TimeSec}>
              <div className={ContactCss.ContentInside}>
                <img src="/assets/images/contact-img-4.svg" alt="" />
                <div className={ContactCss.Text}>
                  <p>Monday to Friday</p>
                  <h6>8:00am - 04:30pm</h6>
                  <p className="mt-4">Saturday</p>
                  <h6>8:30am - 12:30pm</h6>
                </div>
              </div>
            </div>
          </div>
          <div className={ContactCss.Map}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.708446836398!2d86.1907502!3d22.813264699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f5e39e000ddfd9%3A0x5c0dfa9771c7d7bf!2sXLRI%20-%20Xavier%20School%20of%20Management!5e0!3m2!1sen!2sin!4v1658294562339!5m2!1sen!2sin"
              width="100%"
              height="600"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
