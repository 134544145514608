import React from "react";
import AssociateCss from "./AssociateInstitutes.module.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
const AssociateInstitutes = () => {
  return (
    <div>
      <div className={AssociateCss.AssociateInstitutes}>
        <Container>
          <div className={AssociateCss.TextInnPoint}>
            <h2>XAT ASSOCIATES</h2>
            <p className={AssociateCss.TextPointsBold}>
              If the file size and format are not as prescribed, an error
              message will be displayed. While filling in the Online Application
              Form the candidate will be provided with a link to upload his
              photograph and signature. Procedure for Uploading the Photograph
              and Signature
            </p>
            <p className={AssociateCss.TextPoints}>
              Register for XAT 2023 at <span><Link to='/'>www.xatonline.in</Link></span> and make XAT payment.
            </p>
            <p className={AssociateCss.TextPoints}>
              Obtain the Application Form after registering online by making
              appropriate payment. Further details can be obtained from the
              respective Institute’s website or their Admissions office. Follow
              their registration/application procedure.
            </p>
            <p className={AssociateCss.TextPoints}>
              Candidates may please note that XLRI does not ascertain quality of
              the degree or programme(s) offered by XAT Associate Institutes.
            </p>
            <p className={AssociateCss.TextPoints}>
              Please note that each institute independently decides the
              weightages to be given for each of the test components on the
              basis of its need. XLRI does not play any role in selection of the
              candidates in these institutes.
            </p>
            <h6>Note:</h6>
            <p className={AssociateCss.TextPoints}>
              All payments made for registration for XAT and XLRI are
              non-refundable.
            </p>
            <p className={AssociateCss.TextPoints}>
              The XAT Score Card containing the performance of the candidate in
              XAT 2023 has to be downloaded by the candidate and preserve it for
              future communications.
            </p>
            <h2>XAT ASSOCIATE MEMBERS</h2>
            <div className={AssociateCss.TableSection}>
              <table className="table table-responsive ">
                <thead>
                  <tr>
                    <th scope="col">SL</th>
                    <th scope="col">XAT ASSOCIATE INSTITUTE</th>
                    <th scope="col">LOCATION</th>
                    <th scope="col">EMAIL ID</th>
                    <th scope="col">WEBSITE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>AIMS school Of Business (AIMS Institutes)</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">8</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">9</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">10</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
           <div className={AssociateCss.TableXami}>
           <h2>XAMI MEMBERS</h2>
            <div className={AssociateCss.TableSection}>
              <table class="table table-responsive ">
                <thead>
                <tr>
                    <th scope="col">SL</th>
                    <th scope="col">XAT ASSOCIATE INSTITUTE</th>
                    <th scope="col">LOCATION</th>
                    <th scope="col">EMAIL ID</th>
                    <th scope="col">WEBSITE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>AIMS school Of Business (AIMS Institutes)</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">8</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">9</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                  <tr>
                    <th scope="row">10</th>
                    <td>ABBS School of Management</td>
                    <td>Bengaluru</td>
                    <td>admissions@acharyabbs.ac.in</td>
                    <td>www.abbssm.edu.in</td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
           </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AssociateInstitutes;
