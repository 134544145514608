import { createSlice } from "@reduxjs/toolkit";
import { setToken } from "../helpers/utils";
import axios from "axios";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail
} from "firebase/auth";
import { firebaseAuth } from "../firebase";
import { toast } from "react-toastify";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    uid: null,
    token: null,
  },
  reducers: {
    createUser: (state, action) => {
      setToken(action.payload);
      return {
        isAuthenticated: true,
        uid: action.payload.uid,
        token: action.payload.token,
      };
    },
    removeUser: (state, action) => {
      setToken();
      return {
        isAuthenticated: false,
        uid: null,
        token: null,
      };
    },
  },
});

export const { createUser, removeUser } = authSlice.actions;

export function getJwtToken(dispatch, data, uid, loading, register) {
  let config = {
    headers: {
      Authorization: "WIupVkN9MK",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Request-Method": "POST",
    },
  };
  let body = {
    uuid: uid,
  };
  axios
    .post(process.env.REACT_APP_LOGIN_REST_ENDPOINT, body, config)
    .then(async (res) => {
      if (res?.data?.status === "success") {
        let token = res?.data?.token;
        await dispatch(createUser({ token, uid }));
        console.log(uid)
        register(data,uid);
      }
    })
    .catch((e) => {
      toast.error("Something went wrong!");
      loading(false);
      const user = firebaseAuth.currentUser;
      user.delete().then(() => {
        toast.error(`Something went wrong`);
      });
      // hidePopup();
    });
}

export const registerUser = (dispatch, data, loading, register) => {
  return function () {
    createUserWithEmailAndPassword(firebaseAuth, data?.emailId?.toLowerCase(), data.password)
      .then(({ user }) => {
        getJwtToken(dispatch, data, user.uid, loading, register);
      })
      .catch(async (error) => {
        loading(false);
        if (error.code === "auth/email-already-in-use") {
          try {
            const signInMethods = await fetchSignInMethodsForEmail(
             firebaseAuth, data.emailId
            );
            if (signInMethods.length === 0) {
              console.error(
                `Email address ${data.emailId} is not associated with any account.`
              );
              return null;
            }

            const { user } = await signInWithEmailAndPassword(
              firebaseAuth,
              data.emailId,
              data.password
            );
            getJwtToken(dispatch, data, user.uid, loading, register);
          } catch (error) {
            console.error(error);
          }
        } else {
          // handle other errors
          console.error(error);
        }
        // hidePopup();
      });
  };
};

export default authSlice.reducer;
