import React, { useState } from "react";
import { Container } from "react-bootstrap";
import landingPageCss from "./LandingPage.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import VideoPopup from "./VideoPopup/VideoPopup";
import VideoPopupTwo from "./VideoPopup/VideoPopupTwo";
import VideoPopupThree from "./VideoPopup/VideoPopupThree";

const LandingPage = (props) => {
  const [videoModalShow, setVideoModalShow] = useState(false);
  const [videoModalShowTwo, setVideoModalShowTwo] = useState(false);
  const [videoModalShowThree, setVideoModalShowThree] = useState(false);
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    loop: true,
    autoplay: true,
    responisve: [],
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 8,
      },
    },
  };
  const optionsTwo = {
    dots: false,
    margin: 4,
    loop: true,
    nav: false,
    autoplay: true,
    autoplayTimeout: 2000,
    responsiveClass: true,
    responsive: {
      300: {
        items: 1,
        nav: true,
      },
      600: {
        items: 1,
        nav: true,
      },
      1000: {
        items: 3,
        nav: true,
        loop: true,
      },
    },
  };
  return (
    <div>
      <div className="landingPageMainSec">
        <div className={landingPageCss.LandingPageMain}>
          <Container>
            <div className={landingPageCss.MainBanner}>
              <div className={landingPageCss.LeftSec}>
                <div className={landingPageCss.ContentWrap}>
                  <h1>
                    XAT <span>2023</span>
                  </h1>
                  <h2>Xavier Aptitude Test</h2>
                  <div className="Whitebox">
                    <div className="marquee">
                      <p>
                        {" "}
                        XLRI BM, HRM results are announced, kindly login to your
                        dashboard to check the same.
                      </p>
                      <p>
                        XLRI has announced GD/PI shortlist for BM, HRM & EX-PGDM
                        programs. Login to your dashboard to view the same
                      </p>
                      <p>
                        Document upload window for BM, HRM & Ex-PGDM is open and
                        shall close by 16th February 2023, midnight.
                      </p>
                      <p>XLRI BM and HRM Interview slot booking is closed.</p>
                      <p>Click here to download the XAT 2023 Scorecard.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={landingPageCss.RightSec}>
                <img src="/assets/images/banner-img.webp" alt="" />
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className={landingPageCss.AboutSecMain}>
        <Container>
          <div className={landingPageCss.AboutInnerSec}>
            <div className={landingPageCss.AboutLeftSec}>
              <img
                className="img-fluid"
                src="/assets/images/About-img-new.webp"
                alt=""
              />
            </div>
            <div className={landingPageCss.AboutRightSec}>
              <h1>
                About <span>XAT 2023</span>
              </h1>
              <p>
                XAT 2023 will be conducted on Sunday, January 2, 2023. XLRI
                conducts XAT on behalf of XAMI. For more than 70 years XLRI is
                conducting XAT at all India level to select the most appropriate
                students for management education. The XAT score is being used
                by more than 160 institutes for the admission.
              </p>
              <p>
                XAT 2023 would be conducted across India in the following
                cities.
              </p>
              <p>
                Agra, Ahmedabad, Aurangabad City (Maharashtra), Allahabad,
                Ambala, Amravati, Amritsar, Bengaluru, Berhampur, Bhatinda,
                Bhilai Nagar, Bhopal, Bhubaneswar, Bokaro Steel City,
                Chandigarh/Mohali, Chennai, Coimbatore, Cuttack, Dehradun,
                Delhi-NCR, Dhanbad, Dibrugarh, Durgapur/Asansol, Ernakulam,
                Gandhinagar, Goa, Gorakhpur, Guwahati, Gwalior, Hooghly,
                Hubballi(Hubli), Hyderabad, Indore, Jabalpur, Jaipur, Jammu,
                Jamshedpur, Kannur, Kanpur, Kolkata, Kota, Kottayam, Kurnool,
                Kurukshetra, Lucknow, Ludhiana, Madurai, Mangalore, Mumbai,
                Mysuru(Mysore), Nagpur, Nashik, Patna, Pune, Raipur,
                Rajahmundry, Ranchi, Roorkee, Rourkela, Sambalpur, Siliguri,
                Surat, Thiruvananthapuram Tiruchirappalli, Tirupathi,
                Tiruvallur, Udaipur, Udupi Vadodara, Varanasi, Vijayawada,
                Visakhapatnam, Warangal.
              </p>
            </div>
          </div>
        </Container>
      </div>

      <div className={landingPageCss.VedioSecMain}>
        <Container>
          <div className={landingPageCss.VedioSecWrap}>
            <h1>
              <span>XAT </span>Toppers Tips
            </h1>
            <div className={landingPageCss.VedioBox}>
              <div className={landingPageCss.Box}>
                <img src="/assets/images/video-overlay-1.webp" alt="" />

                <div>
                  <button
                    onClick={() => setVideoModalShow(true)}
                    className={landingPageCss.PlayerButton}
                  ></button>
                  <VideoPopup
                    show={videoModalShow}
                    onHide={() => setVideoModalShow(false)}
                  />
                </div>
                <div className={landingPageCss.TextBox}>
                  <h6>Sarthak Kumar</h6>
                  <p>BM, 2018-20</p>
                </div>
              </div>
              <div className={landingPageCss.Box}>
                <img src="/assets/images/video-overlay-2.webp" alt="" />
                <div>
                  <button
                    onClick={() => setVideoModalShowTwo(true)}
                    className={landingPageCss.PlayerButton}
                  ></button>
                  <VideoPopupTwo
                    show={videoModalShowTwo}
                    onHide={() => setVideoModalShowTwo(false)}
                  />
                </div>
                <div className={landingPageCss.TextBox}>
                  <h6>Snigdha Singh</h6>
                  <p>HR, 2017-19</p>
                </div>
              </div>
              <div className={landingPageCss.Box}>
                <img src="/assets/images/video-overlay-3.webp" alt="" />
                <div>
                  <button
                    onClick={() => setVideoModalShowThree(true)}
                    className={landingPageCss.PlayerButton}
                  ></button>
                  <VideoPopupThree 
                   show={videoModalShowThree}
                   onHide={() => setVideoModalShowThree(false)}/>
                </div>
                <div className={landingPageCss.TextBox}>
                  <h6>Aastha Sharma</h6>
                  <p>BM, 2017-19</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="brand-wrapper-main">
        <div className={landingPageCss.BrandWrapper}>
          <Container>
            <div className={landingPageCss.BrandMembersSec}>
              <h1>
                <span>XAMI</span> Members
              </h1>
              <div className="brand-main">
                <OwlCarousel {...options}>
                  <div className={landingPageCss.BrandBox}>
                    <img src="/assets/images/brand-1.jpg" alt="" />
                  </div>

                  <div className={landingPageCss.BrandBox}>
                    <img src="/assets/images/main-logo.webp" alt="" />
                  </div>

                  <div className={landingPageCss.BrandBox}>
                    <img src="/assets/images/brand-4.jpg" alt="" />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img src="/assets/images/brand-6.jpg" alt="" />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img src="/assets/images/brand-7.jpg" alt="" />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img src="/assets/images/brand-8.jpg" alt="" />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img src="/assets/images/brand-9.jpg" alt="" />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img src="/assets/images/brand-51.png" alt="" />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img src="/assets/images/brand-img.jpg" alt="" />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img src="/assets/images/brand-xiss.jpg" alt="" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <div className={landingPageCss.BrandAsoSec}>
              <h1>
                <span>XAT</span> Associate Members
              </h1>
              <div className="brand-main-one">
                <OwlCarousel {...options}>
                  <div className={landingPageCss.BrandBox}>
                    <img
                      src="/assets/images/bg-img-1.png"
                      className={landingPageCss.ImgL}
                      alt=""
                    />
                  </div>

                  <div className={landingPageCss.BrandBox}>
                    <img
                      src="/assets/images/bg-img-2.png"
                      className={landingPageCss.ImgL}
                      alt=""
                    />
                  </div>

                  <div className={landingPageCss.BrandBox}>
                    <img
                      src="/assets/images/bg-img-3.png"
                      className={landingPageCss.ImgL}
                      alt=""
                    />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img
                      src="/assets/images/bg-img-4.png"
                      className={landingPageCss.Img}
                      alt=""
                    />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img
                      src="/assets/images/bg-img-5.png"
                      className={landingPageCss.Img}
                      alt=""
                    />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img
                      src="/assets/images/bg-img-6.png"
                      className={landingPageCss.Img}
                      alt=""
                    />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img
                      src="/assets/images/bg-img-7.png"
                      className={landingPageCss.Img}
                      alt=""
                    />
                  </div>
                  <div className={landingPageCss.BrandBox}>
                    <img
                      src="/assets/images/bg-img-8.png"
                      className={landingPageCss.ImgL}
                      alt=""
                    />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="GalleryMainCommon">
        <div className={landingPageCss.GalleryMain}>
          <Container>
            <h1>Gallery</h1>
            <div className={landingPageCss.GallerySecOne}>
              <OwlCarousel {...optionsTwo}>
                <div className={landingPageCss.SliderBox}>
                  <img src="/assets/images/gallery-img-1.webp" alt="" />
                </div>

                <div className={landingPageCss.SliderBox}>
                  <img src="/assets/images/gallery-img-2.webp" alt="" />
                </div>

                <div className={landingPageCss.SliderBox}>
                  <img src="/assets/images/gallery-img-3.webp" alt="" />
                </div>
                <div className={landingPageCss.SliderBox}>
                  <img src="/assets/images/gallery-img-2.webp" alt="" />
                </div>
              </OwlCarousel>
            </div>
            <div className={landingPageCss.GallerySecTwo}>
              <OwlCarousel {...optionsTwo}>
                <div className={landingPageCss.SliderBox}>
                  <img src="/assets/images/gallery-img-4.webp" alt="" />
                </div>

                <div className={landingPageCss.SliderBox}>
                  <img src="/assets/images/gallery-img-5.webp" alt="" />
                </div>

                <div className={landingPageCss.SliderBox}>
                  <img src="/assets/images/gallery-img-2.webp" alt="" />
                </div>
                <div className={landingPageCss.SliderBox}>
                  <img src="/assets/images/gallery-img-4.webp" alt="" />
                </div>
              </OwlCarousel>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
