import React from 'react'
import { Modal } from "react-bootstrap";
const VideoPopupThree = (props) => {
  return (
    <div>
        <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="VideoPopup">
        <div>
            <span className="close-icon" onClick={props.onHide}>
              <img src="/assets/images/close.svg" alt="Close" />
            </span>
          
          </div>
          <iframe width="100%" height="325" src="https://www.youtube.com/embed/e5E3bIYDyVI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
             </div>
      </Modal>
    </div>
  )
}

export default VideoPopupThree