import { Form } from "react-bootstrap";
import styles from "../forms.module.scss";

function Dropdown({
  field,
  values,
  touched,
  handleChange,
  handleBlur,
  errors,
  formFields,
  setFieldValue,
  searchParamsObj
}) {
  const fieldOptions = [...new Set(field?.option?.values)];
  const isBold = field?.highlight;

  const staticField = "static";

  function handleVisibledFieldValues() {
    const childFields = formFields?.filter(
      (el) => el?.visible?.key === field?._id
    );

    childFields?.map((el) => {
      setFieldValue(el?._id, "");
    });
  }

  return (
    <>
      {!field?.visible || values[field.visible.key] === field.visible.value ? (
        <Form.Group className={styles.DropdownField}>
          {values?.[field?._id] && (
            <Form.Label
              className={styles.DropdownLabelField}
              dangerouslySetInnerHTML={{
                __html: field?.title?.replace(
                  /#(.*?)#/g,
                  '<span style="color: red;">($1)</span>'
                ),
              }}
            ></Form.Label>
          )}
          <Form.Select
            type="text"
            name={field?._id}
            value={values[field?._id]}
            onChange={(e) => {
              handleVisibledFieldValues();
              handleChange(e);
            }}
            onBlur={handleBlur}
            disabled={searchParamsObj[field?.title]}
          >
            {field?.isPrice ? (
              <>
                <option
                  dangerouslySetInnerHTML={{
                    __html: field?.title?.replace(
                      /#(.*?)#/g,
                      '<span style="color: red;">($1)</span>'
                    ),
                  }}
                  value=""
                ></option>
                {field?.additional?.map((option, idx) => (
                  <option key={idx} value={option?.value}>
                    {option?.value}
                  </option>
                ))}
              </>
            ) : (
              <>
                {field?.option?.dataFetch === staticField ? (
                  <>
                    <option value="">
                      {field?.title?.replace(
                        /#(.*?)#/g,
                        '<span class="errorMessage">$1</span>'
                      )}
                    </option>
                    {fieldOptions?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Form.Select>
          {touched[field?._id] && errors[field?._id] && (
            <div className="errorMessage">{errors[field?._id]}</div>
          )}
        </Form.Group>
      ) : null}
    </>
  );
}

export default Dropdown;
