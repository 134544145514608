import { useState } from "react";

function ReadMoreButton({ children }) {
  const text = children;
  const [isReadMoreShown, setReadMore] = useState(false);
  return (
    <div>
      <p className="readMorePara">
        {isReadMoreShown ? text : text?.slice(0, 250)}
        <span
          style={{ fontWeight: "500", fontSize: "21px" }}
          onClick={() => setReadMore((prevState) => !prevState)}
          className="read-or-hide"
        >
          {isReadMoreShown ? "   Show less" : "...Read more"}
        </span>
      </p>
    </div>
  );
}

export default ReadMoreButton;
