import { gql, useMutation } from "@apollo/client";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FullLoader } from "../loader";

function PaymentCheckPage({
  data,
  disableBtn,
  applicantId,
  refetch,
  formData,
}) {
  const [showLoader, setShowLoader] = useState(false);
  const paymentDetails = useSelector(
    (state) => state.auth?.applicationDetails?.payment
  );

  const navigate = useNavigate();

  const [updatePayment] = useMutation(UPDATE_APPLICATION_PAYMENT);

  useEffect(() => {
    function onScriptLoad() {
      setShowLoader(true);
      var config = {
        root: "",
        flow: "DEFAULT",
        data: {
          orderId: data?.ORDERID,
          token: data?.txnToken,
          tokenType: "TXN_TOKEN",
          amount: data?.amount,
        },
        merchant: {
          redirect: data?.ORDERID?.includes("FORM_") ? false : true,
        },
        handler: {
          transactionStatus: function (res) {
            if (res?.ORDERID?.includes("FORM_")) {
              axios
                .post(`${process.env.REACT_APP_PAYMENT_ENPOINT}/webhook2`, res)
                .then((resp) => {
                  if (resp.data && res.data.STATUS === "TXN_SUCCESS") {
                    const url =  formData?.dynamic?.redirectUrl ||
                    "https://xlri.ac.in/thank-you";
                    window.open(url,"_self")
                     
                  } else {
                    toast.error(`Payment Failed ${res?.data?.RESPMSG}`);
                  }
                });
            }
            window.Paytm.CheckoutJS.close();
            window.open("https://xlri.ac.in/thank-you","_self");
          },
          onPaymentSuccess: function (response) {
            window.Paytm.CheckoutJS.close();
          },
          notifyMerchant: function (eventName, data) {},
        },
      };

      if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
          // initialze configuration using init method
          window.Paytm.CheckoutJS.init(config)
            .then(function onSuccess() {
              // after successfully updating configuration, invoke JS Checkout
              setShowLoader(false);
              disableBtn(false);
              window.Paytm.CheckoutJS.invoke();
            })
            .catch(function onError(error) {
              toast.error(`Something went wrong:${error.message}`);
              setShowLoader(false);
              disableBtn(false);
            });
        });
      }
    }

    const script = document.createElement("script");
    script.src = `${process.env.REACT_APP_PAYTM_ENDPOINT}/${data?.MID}.js`;
    script.type = "application/javascript";
    script.onload = onScriptLoad;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [data]);

  return <div>{showLoader && <FullLoader />}</div>;
}

export default PaymentCheckPage;

const UPDATE_APPLICATION_PAYMENT = gql`
  mutation updateApplicationPaymentStatus(
    $paytmResp: PaytmResp
    $razorPayResp: PaytmResp
    $applicantId: String!
  ) {
    updateApplicationPaymentStatus(
      paytmResp: $paytmResp
      razorPayResp: $razorPayResp
      applicantId: $applicantId
    ) {
      _id
      uuid
      applicationId
      status
    }
  }
`;
