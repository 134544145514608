import React, { useEffect, useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import loginCss from "./LoginStyle.module.scss";

function TooltipComponent({ children, options }) {
  const [placement, setPlacement] = useState("left");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setPlacement("bottom");
      } else {
        setPlacement("left");
      }
    };

    // Set initial placement
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <OverlayTrigger
        trigger={["focus"]}
        placement={placement}
        overlay={
          <Popover id="popover-trigger-hover-focus" title="Popover left">
            <div className={loginCss.popoverStyle}>
              {Object.keys(options?.message)?.map((key) => (
                <p
                  key={key}
                  style={options?.message[key]?.value ? { color: "green" } : {}}
                >
                  {options?.message[key]?.label}
                </p>
              ))}
            </div>
          </Popover>
        }
      >
        {children}
      </OverlayTrigger>
    </div>
  );
}

export default TooltipComponent;
