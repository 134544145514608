import React, { useRef, useState } from "react";
import loginCss from "./LoginStyle.module.scss";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { registrationSchema } from "../helpers/validators/ValidationSchema";
import * as StateList from "../helpers/jsonFiles/State.json";
import * as CountryList from "../helpers/jsonFiles/countryCode.json";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { getJwtToken, registerUser } from "../redux/auth";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import LoginOtp from "./LoginOtp";
import { FullLoader } from "../helpers/loader";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { firebaseAuth } from "../firebase";
import { CAPTCHA_SITE_KEY } from "../helpers/constants";
import styles from "../components/LandingPageProgram/LandingPageProgram.module.scss";
import MessageForLogin from "./MessageForLogin";
import TooltipComponent from "./tooltipComponent";
import eyeOpenImg from "../assets/eye-solid.svg";
import eyeCloseImg from "../assets/eye-slash-solid.svg";
import { createUserWithEmailAndPassword } from "firebase/auth";

const Register = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [otpView, setOtpView] = useState(false);
  const stateArr = [];
  const countryArr = [];
  const [registerLoading, setRegisterLoading] = useState(false);
  const [resetFormCallback, setResetFormCallback] = useState(null); // State to store the callback for resetting form

  const [tooltipOptions, setTooltipOptions] = useState({
    message: {
      smAlph: {
        label: "Atleast one small letter alphabets required",
        value: false,
      },
      lgAlph: {
        label: "Atleast one Capital letter alphabet required",
        value: false,
      },
      min: {
        label: "Minimum 6 characters required",
        value: false,
      },
      spcl: {
        label: "Atleast one special character required",
        value: false,
      },
      number: {
        label: "Atleast one number required",
        value: false,
      },
    },
  });
  const [showLoginMsg, setShowLoginMessage] = useState({
    id: null,
    show: false,
  });
  const currentUid = useRef(null);
  const captchaRef = useRef();
  const [registrationDetails, { loading }] = useMutation(REGISTRATION);
  const [resendOtp, { loading: resendLoading }] = useMutation(RESEND_OTP);
  // const [mutationForAnotherRegistration, { loading: registrationLoading }] =
  //   useMutation(REGISTER_FILE);
  const uid = useSelector((state) => state.auth.uid);
  const [getUserDetails] = useLazyQuery(GET_USER_DETAILS);

  const applicationCode = searchParams.get("applicationCode");
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");
  // const utmContent = searchParams.get("utm_content");

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  function onSubmit(data, { resetForm }) {
    setRegisterLoading(true);
    setResetFormCallback(() => resetForm); // Store the resetForm callback

    dispatch(
      register(
        dispatch,
        data,
        setRegisterLoading,
        userRegistration
        // props.onHide
      )
    );
  }

  function handleCheckPassword(value) {
    if (/[a-z]/.test(value)) {
      setTooltipOptions((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          smAlph: {
            ...prev.message.smAlph,
            value: true,
          },
        },
      }));
    } else {
      setTooltipOptions((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          smAlph: {
            ...prev.message.smAlph,
            value: false,
          },
        },
      }));
    }
    if (/[A-Z]/.test(value)) {
      setTooltipOptions((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          lgAlph: {
            ...prev.message.lgAlph,
            value: true,
          },
        },
      }));
    } else {
      setTooltipOptions((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          lgAlph: {
            ...prev.message.lgAlph,
            value: false,
          },
        },
      }));
    }

    if (/[@$!%*?_&]/.test(value)) {
      setTooltipOptions((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          spcl: {
            ...prev.message.spcl,
            value: true,
          },
        },
      }));
    } else {
      setTooltipOptions((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          spcl: {
            ...prev.message.spcl,
            value: false,
          },
        },
      }));
    }

    if (/[0-9]/.test(value)) {
      setTooltipOptions((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          number: {
            ...prev.message.number,
            value: true,
          },
        },
      }));
    } else {
      setTooltipOptions((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          number: {
            ...prev.message.number,
            value: false,
          },
        },
      }));
    }

    if (/.{6,}/.test(value)) {
      setTooltipOptions((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          min: {
            ...prev.message.min,
            value: true,
          },
        },
      }));
    } else {
      setTooltipOptions((prev) => ({
        ...prev,
        message: {
          ...prev.message,
          min: {
            ...prev.message.min,
            value: false,
          },
        },
      }));
    }
  }

  const register = (dispatch, data, loading, register) => {
    return function () {
      createUserWithEmailAndPassword(
        firebaseAuth,
        data?.emailId?.toLowerCase(),
        data.password
      )
        .then(({ user }) => {
          getJwtToken(dispatch, data, user.uid, loading, register);
        })
        .catch((err) => {
          if (err.code === "auth/email-already-in-use") {
            getUserDetails({
              variables: {
                email: data?.emailId.toLowerCase(),
              },
              context: { clientName: "utility" },
            })
              .then((res) => {
                // getJwtToken(
                //   dispatch,
                //   res?.data?.getUserDetailFromFirebase?.uid,
                //   data
                // );
                getJwtToken(
                  dispatch,
                  data,
                  res?.data?.getUserDetailFromFirebase?.uid,
                  loading,
                  register
                );
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            toast.error(`Something went wrong ${err}`);
          }
        });
    };
  };

  function userRegistration(data, id) {
    currentUid.current = id;
    registrationDetails({
      variables: {
        createXatRegisterInput: {
          applicationType: "XAT - 2022",
          applicationId: applicationCode,
          name: data.name,
          email: data?.emailId?.toLowerCase(),
          city: data.city,
          state: data.state,
          phone: data.mobile,
          utm_source: utmSource || null,
          utm_medium: utmMedium || null,
          utm_campaign: utmCampaign || null,
          code: data.countryCode,
        },
      },
      context: {
        clientName: "admission",
      },
    })
      .then((res) => {
        // props.onHide(true);
        setOtpView(true);
        setRegisterLoading(false);
      })
      .catch((err) => {
        if (err.message.split(":")[1] == 401) {
          resendOtp({
            variables: {
              uuid: uid,
            },
            context: {
              clientName: "admission",
            },
          })
            .then(() => {
              toast(`OTP Send successfully!`);
              setOtpView(true);
            })
            .catch((err) => {
              toast.error(`Something went wrong:${err.message}`);
            });

          setRegisterLoading(false);
        } else {
          setRegisterLoading(false);
          toast.error(`Something went wrong! ${err}`);
        }

        // else if (err.message.split(":")[1] == 403) {
        //   console.log("user again");
        //   mutationForAnotherRegistration({
        //     variables: {
        //       createXatRegisterInput: {
        //         applicationType: "XAT - 2022",
        //         applicationId: applicationCode,
        //         name: data.name,
        //         email: data.emailId,
        //         city: data.city,
        //         state: data.state,
        //         phone: data.mobile,
        //         utm_source: utmSource || null,
        //         utm_medium: utmMedium || null,
        //         utm_campaign: utmCampaign || null,
        //         code: data.countryCode,
        //       },
        //     },
        //     context: {
        //       clientName: "admission",
        //     },
        //   })
        //     .then(() => {
        //       toast.success("Registered For this Application Successfully!");
        //       setShowLoginMessage({ show: true, id: currentUid.current });
        //     })
        //     .catch((err) => {
        //       toast.error(`${err.message}`);
        //     });
        // }
        // else {
        //   const user = firebaseAuth.currentUser;
        //   user.delete().then(() => {
        //     console.log("deleted successfullyy");
        //   });
        //   setRegisterLoading(false);
        //   toast.error(`Something went wrong : ${err.message}`);
        // }
      });
  }

  Object.keys(StateList).map((item) => {
    return stateArr.push(item);
  });

  Object.values(CountryList).map((item, i) => {
    return countryArr.push(item.code);
  });

  return (
    <div className={styles.contactTxtDiv1}>
      <div>
        {registerLoading && <FullLoader />}
        {loading && <FullLoader />}
        <div className={loginCss.CommonWidth}>
          <div className="col-md-12">
            <h3>Register Now</h3>
          </div>
          <Formik
            initialValues={{
              name: "",
              emailId: "",
              mobile: "",
              password: "",
              state: "",
              city: "",
              countryCode: "91",
              addRecaptcha: "",
              ifAgree: false,
            }}
            validationSchema={registrationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="name"
                        placeholder="Name*"
                      />
                      {errors.name && touched.name ? (
                        <div className="errorMessage">{errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        name="emailId"
                        value={values.emailId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="emailid"
                        placeholder="Email ID*"
                      />
                      {errors.emailId && touched.emailId ? (
                        <div className="errorMessage">{errors.emailId}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="form-group">
                      <select
                        className="slect-main"
                        name="countryCode"
                        value={values.countryCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {countryArr.map((item) => (
                          <option key={item} value={item}>
                            +{item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-9 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Mobile number*"
                      />
                      {errors.mobile && touched.mobile ? (
                        <div className="errorMessage">{errors.mobile}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12 col-12">
                    <div className="form-group">
                      <TooltipComponent options={tooltipOptions}>
                        <div className={loginCss.eyeIconwrapper}>
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values.password}
                            onChange={(e) => {
                              handleChange(e);
                              handleCheckPassword(e.target.value);
                            }}
                            onBlur={handleBlur}
                            placeholder="Any Password of your Choice*"
                            className="form-control"
                            style={{ paddingRight: "40px" }} // Adjust padding for eye icon
                          />
                          <span
                            onClick={toggleShowPassword}
                            // className={`position-absolute end-0 translate-middle-y ${loginCss.eyeIcon}`}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={!showPassword ? eyeOpenImg : eyeCloseImg}
                              alt="Toggle Password Visibility"
                              width="24"
                              height="24"
                            />
                          </span>
                        </div>
                      </TooltipComponent>
                      {errors.password && touched.password ? (
                        <div className="errorMessage">{errors.password}</div>
                      ) : null}
                    </div>
                  </div>
                  {values.countryCode === "91" && (
                    <>
                      <div className="col-6">
                        <div className="form-group">
                          <select
                            className="slect-main"
                            placeholder="State"
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option className="option-head" value="" disabled>
                              State
                            </option>
                            {stateArr.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                          {errors.state && touched.state ? (
                            <div className="errorMessage">{errors.state}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <select
                            className="slect-main"
                            placeholder="City"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="" disabled>
                              City
                            </option>
                            {values.state &&
                              StateList[`${values.state}`].map((item) => {
                                return (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                          {errors.city && touched.city ? (
                            <div className="errorMessage">{errors.city}</div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-md-12 mb-3">
                    <div className="form-group">
                      <ReCAPTCHA
                        sitekey={CAPTCHA_SITE_KEY}
                        ref={captchaRef}
                        render="explicit"
                        size="invisible"
                        onChange={(response) => {
                          setFieldValue("addRecaptcha", response);
                        }}
                      />
                      {errors.addRecaptcha && touched.addRecaptcha ? (
                        <div className="errorMessage">
                          {errors.addRecaptcha}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.contactBoxTxt}>
                    <div className="form-check d-flex">
                      <input
                        name="ifAgree"
                        type="checkbox"
                        checked={values.ifAgree}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: "20px", marginRight: "5px" }}
                        id="flexCheckDefault"
                      />

                      <label
                        className="form-check-label mb-3"
                        htmlFor="flexCheckDefault"
                      >
                        I agree to receive information regarding my registration
                        for this program*
                      </label>
                    </div>
                    {errors.ifAgree && touched.ifAgree ? (
                      <div className="errorMessage">{errors.ifAgree}</div>
                    ) : null}
                  </div>
                  <p>
                    Once you click on register you will receive a 6-digit code
                    on your mobile number that you have registered here. Please
                    enter the code to proceed with the registration.
                  </p>
                  <p className={loginCss.RedText}>
                    If you are unable to receive the email communication, kindly
                    check whether you have unsubscribed the email id
                  </p>
                  <div className="col-sm-12 col-12">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="continuesBtnMain mt-2"
                        disabled={loading || registerLoading}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="primary"
                            size="sm"
                          />
                        )}
                        {!loading && <>REGISTER</>}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <LoginOtp
        show={otpView}
        onHide={() => setOtpView(false)}
        setResetForm={resetFormCallback}
        currentId={currentUid.current}
      />
      <MessageForLogin
        show={showLoginMsg.show}
        id={showLoginMsg.id}
        onHide={() => setShowLoginMessage({ show: false, id: null })}
      />
    </div>
  );
};

export default Register;

const REGISTRATION = gql`
  mutation createXatRegister($createXatRegisterInput: CreateXatRegisterInput!) {
    createXatRegister(createXatRegisterInput: $createXatRegisterInput) {
      _id
      status
    }
  }
`;

const RESEND_OTP = gql`
  mutation resendOTPApplicant($uuid: String!) {
    resendOTPApplicant(uuid: $uuid) {
      _id
      uuid
      applicationId
      appStatus
      others
      status
    }
  }
`;

const REGISTER_FILE = gql`
  mutation registerApplication(
    $createXatRegisterInput: CreateXatRegisterInput!
  ) {
    registerApplication(createXatRegisterInput: $createXatRegisterInput) {
      _id
      uuid
      status
    }
  }
`;

const GET_USER_DETAILS = gql`
  query getUserDetailFromFirebase($email: String!) {
    getUserDetailFromFirebase(email: $email) {
      uid
    }
  }
`;
