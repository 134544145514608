import React from 'react'
import { Container } from "react-bootstrap";
import TopperCss from "./Toppers.module.scss";
const Toppers = () => {
  return (
    <div>
     <div className='mt-5 mb-5'>
     <div className='col-md-12'>
       
       <div className={TopperCss.VedioSecMain}>
         <Container>
         <h1>Diversity Series</h1>
           <div className={TopperCss.VedioSecWrap}>
             <div className={TopperCss.VedioBox}>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/overlay-img-4.png" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>Dr. Nadeem</h6>
                   
                 </div>
               </div>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/video-overlay-3.webp" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>Lawyer Erika</h6>
                  
                 </div>
               </div>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/video-overlay-2.webp" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>CA Ruchi</h6>
                   
                 </div>
               </div>
               
             </div>
           </div>
         </Container>
       </div>
       </div>
       <div className='col-md-12'>
       <div className={TopperCss.VedioSecMain}>
         <Container>
           <div className={TopperCss.VedioSecWrap}>
            
             <div className={TopperCss.VedioBox}>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/overlay-img-4.png" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>Dr. Nadeem</h6>
                   
                 </div>
               </div>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/video-overlay-3.webp" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>Lawyer Erika</h6>
                  
                 </div>
               </div>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/video-overlay-2.webp" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>CA Ruchi</h6>
                   
                 </div>
               </div>
               
             </div>
           </div>
         </Container>
       </div>
         </div>
         <div className='col-md-12'>
         <div className={TopperCss.VedioSecMain}>
         <Container>
          <h1>Topper Series</h1>
           <div className={TopperCss.VedioSecWrap}>
            
             <div className={TopperCss.VedioBox}>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/overlay-img-4.png" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>Dr. Nadeem</h6>
                   
                 </div>
               </div>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/video-overlay-3.webp" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>Lawyer Erika</h6>
                  
                 </div>
               </div>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/video-overlay-2.webp" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>CA Ruchi</h6>
                   
                 </div>
               </div>
               
             </div>
           </div>
         </Container>
       </div>
         </div>
         <div className='col-md-12'>
         <div className={TopperCss.VedioSecMain}>
         <Container>
           <div className={TopperCss.VedioSecWrap}>
            
             <div className={TopperCss.VedioBox}>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/overlay-img-4.png" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>Dr. Nadeem</h6>
                   
                 </div>
               </div>
               <div className={TopperCss.Box}>
                 <img src="/assets/images/video-overlay-3.webp" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>Lawyer Erika</h6>
                  
                 </div>
               </div>
               <div className={TopperCss.Box}>
               <img src="/assets/images/video-overlay-2.webp" alt="" />
                 <div className={TopperCss.PlayerButton}>
                   <button></button>
                 </div>
                 <div className={TopperCss.TextBox}>
                   <h6>CA Ruchi</h6>
                   
                 </div>
                   
                
               </div>
               
             </div>
           </div>
         </Container>
       </div>
         </div>
     </div>
    </div>
  )
}

export default Toppers