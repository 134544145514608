import React, { useRef } from "react";
import loginCss from "./LoginStyle.module.scss";
import { Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import { gql, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../redux/auth";
import { LIVE_URL } from "../helpers/constants";
import { toast } from "react-toastify";
import { FullLoader } from "../helpers/loader";
import MessageForLogin from "./MessageForLogin";
import { useState } from "react";

const LoginOtp = (props) => {
  const inputRefs = useRef([]);

  const [verifyOtp, { loading }] = useMutation(VERIFY_OTP);
  const [resendOtp, { loading: resendLoading }] = useMutation(RESEND_OTP);
  const [showLoginMsg, setShowLoginMessage] = useState(false);

  const dispatch = useDispatch();

  const uid = useSelector((state) => state.auth?.uid);

  function onSubmit(data, { resetForm }) {
    let arr = [];
    Object.values(data).map((item) => {
      return arr.push(item);
    });
    let code = arr.join("");

    verifyOtp({
      variables: {
        otp: code,
      },
      context: {
        clientName: "admission"
      }
    })
      .then((res) => {
        resetForm();
        props.onHide(true);
        dispatch(removeUser());
        props?.setResetForm();
        setShowLoginMessage(true);
      })
      .catch((e) => {
        toast.error(`Something went wrong:${e.message}`);
      });
  }

  const handleOtpInputChange = (index, event) => {
    const value = event.target.value;
    if (value.length > 0 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOtpInputKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !event.target.value) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleOtpResend = () => {
    resendOtp({
      variables: {
        uuid: uid,
      },
      context: {
        clientName: "admission"
      }
    })
      .then(() => {
        toast(`OTP Send successfully!`);
      })
      .catch((err) => {
        toast.error(`Something went wrong:${err.message}`);
      });
  };

  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="commonPopupmainSecCommon">
          {loading && <FullLoader />}
          <div>
            <span className="close-icon" onClick={props.onHide}>
              <img src="/assets/images/close.svg" alt="Close" />
            </span>
          </div>
          <div className={loginCss.CommonWidth}>
            <div className="col-md-12">{/* <h3>Registration 2023</h3> */}</div>
            <Formik
              initialValues={{
                first: "",
                second: "",
                third: "",
                fourth: "",
                fifth: "",
                sixth: "",
              }}
              onSubmit={onSubmit}
            >
              {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={loginCss.Small}>
                        <Form.Group>
                          <input
                            maxLength="1"
                            type="text"
                            name="first"
                            onChange={(event) => {
                              handleChange(event);
                              handleOtpInputChange(0, event);
                            }}
                            onKeyDown={(event) => handleOtpInputKeyDown(0, event)}
                            ref={(ref) => (inputRefs.current[0] = ref)}
                            value={values.first}
                            className={loginCss.SmallBox}
                          />
                        </Form.Group>
                        <Form.Group>
                          <input
                            maxLength="1"
                            type="text"
                            name="second"
                            onChange={(event) => {
                              handleChange(event);
                              handleOtpInputChange(1, event);
                            }}
                            onKeyDown={(event) => handleOtpInputKeyDown(1, event)}
                            ref={(ref) => (inputRefs.current[1] = ref)}
                            value={values.second}
                            className={loginCss.SmallBox}
                          />
                        </Form.Group>
                        <Form.Group>
                          <input
                            maxLength="1"
                            type="text"
                            name="third"
                            onChange={(event) => {
                              handleChange(event);
                              handleOtpInputChange(2, event);
                            }}
                            onKeyDown={(event) => handleOtpInputKeyDown(2, event)}
                            ref={(ref) => (inputRefs.current[2] = ref)}
                            value={values.third}
                            className={loginCss.SmallBox}
                          />
                        </Form.Group>
                        <Form.Group>
                          <input
                            maxLength="1"
                            type="text"
                            name="fourth"
                            onChange={(event) => {
                              handleChange(event);
                              handleOtpInputChange(3, event);
                            }}
                            onKeyDown={(event) => handleOtpInputKeyDown(3, event)}
                            ref={(ref) => (inputRefs.current[3] = ref)}
                            value={values.fourth}
                            className={loginCss.SmallBox}
                          />
                        </Form.Group>
                        <Form.Group>
                          <input
                            maxLength="1"
                            type="text"
                            name="fifth"
                            onChange={(event) => {
                              handleChange(event);
                              handleOtpInputChange(4, event);
                            }}
                            onKeyDown={(event) => handleOtpInputKeyDown(4, event)}
                            ref={(ref) => (inputRefs.current[4] = ref)}
                            value={values.fifth}
                            className={loginCss.SmallBox}
                          />
                        </Form.Group>
                        <Form.Group>
                          <input
                            maxLength="1"
                            type="text"
                            name="sixth"
                            onChange={(event) => {
                              handleChange(event);
                              handleOtpInputChange(5, event);
                            }}
                            onKeyDown={(event) => handleOtpInputKeyDown(5, event)}
                            ref={(ref) => (inputRefs.current[5] = ref)}
                            value={values.sixth}
                            className={loginCss.SmallBox}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <button type="submit" className="continuesBtnMain mt-2">
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>

            <div className={loginCss.thatText}>
              <p onClick={handleOtpResend}>Resend OTP</p>
            </div>
          </div>
        </div>
      </Modal>
      <MessageForLogin
        show={showLoginMsg}
        id={props.currentId}
        onHide={() => setShowLoginMessage(false)}
      />
    </div>
  );
};

export default LoginOtp;

const VERIFY_OTP = gql`
  mutation verifyOtp($otp: String!) {
    verifyOtp(otp: $otp) {
      _id
      status
    }
  }
`;

const RESEND_OTP = gql`
  mutation resendOTPApplicant($uuid: String!) {
    resendOTPApplicant(uuid: $uuid) {
      _id
      uuid
      applicationId
      appStatus
      others
      status
    }
  }
`;
