import React, { useState } from "react";
import { Container } from "react-bootstrap";
import MembershipPopup from "../MembershipPopup";
import ProspectusCss from "./Prospectus.module.scss";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      className={ProspectusCss.ButtonOne}
    >
      {children}
    </button>
  );
}
const Prospectus = () => {
  const [membershipModalShow, setMembershipModalShow] = useState(false);

  return (
    <div>
      <div className="prospectusMainSec">
        <div className={ProspectusCss.ProspectusSecMain}>
          <Container>
            <button
              className={ProspectusCss.ProspectusButton}
              onClick={() => setMembershipModalShow(true)}
            >
              Registration of XAT Associate Institutes for XAT 2023
            </button>
            <MembershipPopup
              show={membershipModalShow}
              onHide={() => setMembershipModalShow(false)}
            />
            <h1>XAT Associate Membership Procedure</h1>
            <p>
              On behalf of the XAT Association, XLRI Jamshedpur has been
              conducting Xavier Aptitude Test (XAT) for the last so many years.
              It is universally acknowledged to be one of the most rigorous,
              reliable, robust, and fair examinations. Many students consider
              XAT to be the ultimate MBA examination in India. More than one
              hundred business schools use XAT scores for admissions.
            </p>
            <p>
              The XAT scores can be used by the associate members to filter
              candidates for admission to their respective institutes. The
              database would include candidates’ profiles including: Name,
              XATID, DOB, Address, Email id and the scores. This allows the
              Associate Member Institutes to directly communicate to the
              candidates.
            </p>
            <p>
              The details of the XAT Associate institute will be published in
              the www.xatonline.in as well as in Bulletin.
            </p>
            <h5>
              In order to obtain the XAT Associate Membership kindly follow the
              below steps
            </h5>
            <h1>Instructions</h1>
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="0">Step 1</CustomToggle>
                </Card.Header>

                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className={ProspectusCss.TextInside}>
                      <h6>
                        Download of Participation Form and Terms & Modalities
                      </h6>
                      <ol>
                        <li>Participation Form</li>
                        <li>Terms & Modalities</li>
                      </ol>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <CustomToggle eventKey="1">Step 2</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className={ProspectusCss.TextInside}>
                      <h6>Registration & Payment of Fee</h6>
                      <ol>
                        <li>Registration</li>
                        <li>Email verification</li>
                        <li>
                          Filling of the XAT Participation form
                          <div className={ProspectusCss.ParaSec}>
                            <p>
                              The form shall ask you the following information:
                            </p>
                            <p className={ProspectusCss.TextPoints}>Name of the Institution/University</p>
                            <p className={ProspectusCss.TextPoints}>Legal Name</p>
                            <p className={ProspectusCss.TextPoints}>Phone No</p>
                            <p className={ProspectusCss.TextPoints}>Email Id</p>
                            <p className={ProspectusCss.TextPoints}>Full Address</p>
                            <p className={ProspectusCss.TextPoints}>State</p>
                            <p className={ProspectusCss.TextPoints}>Pincode</p>
                            <p className={ProspectusCss.TextPoints}>PAN No</p>
                            <p className={ProspectusCss.TextPoints}>TAN No</p>
                            <p className={ProspectusCss.TextPoints}>GST IN</p>
                            <p className={ProspectusCss.TextPoints}>AMOUNT</p>
                          </div>
                        </li>
                        <li>Pay the XAT Associate Membership Fee</li>
                      </ol>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <CustomToggle eventKey="3">Step 3</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className={ProspectusCss.TextInside}>
                      <p>
                        Please send the scanned copy of the filled-in form along
                        with the signed copy of Terms & Modalities to the
                        Admissions Office, XLRI Jamshedpur. (Email Id:
                        admis@xlri.ac.in)
                      </p>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Prospectus;
