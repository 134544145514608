import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Debounce from "../../../helpers/Debounce";
import styles from "../forms.module.scss";

function TextField({
  field,
  values,
  touched,
  handleChange,
  handleBlur,
  idx,
  setFieldValue,
  type,
  getResponses,
  errors,
  searchParamsObj,
}) {
  const isBold = field?.highlight;
  const [defaultNumber, setDefaultNumber] = useState("");

  const types = ["date", "time"];

  function searchInputHandler(e) {
    setFieldValue(field?._id, e.target.value);
    getResponses(e.target.value);
  }
  const optimizedFn = useCallback(Debounce(searchInputHandler));

  useEffect(() => {
    const number = localStorage.getItem("razorpay-number");
    if (number?.length) {
      setDefaultNumber(number);
    }
    if (values[field?._id]) {
      setDefaultNumber(values[field?._id]);
    }
  }, []);

  useEffect(() => {
    if (idx === 0 && defaultNumber) {
      setFieldValue(field?._id, defaultNumber);
      getResponses(defaultNumber);
    }
  }, [defaultNumber]);

  return (
    <>
      {!field?.visible || values[field.visible.key] === field.visible.value ? (
        <Form.Group className={styles.DropdownField}>
          {types.includes(type) || values?.[field?._id] ? (
            <Form.Label className={styles.DropdownLabelField}>
              {field?.title !== "Phone Number"
                ? field?.title?.replace(
                    /#(.*?)#/g,
                    '<span class="errorMessage">$1</span>'
                  )
                : "Mobile Number (10 digit only)".replace(
                    /#(.*?)#/g,
                    '<span class="errorMessage">$1</span>'
                  )}
            </Form.Label>
          ) : null}
          {idx === 0 ? (
            <Form.Control
              type={"tel"}
              name={field?._id}
              defaultValue={defaultNumber}
              disabled={searchParamsObj[field?.title]}
              maxLength={10}
              // value={values[field?._id]}
              onChange={idx === 0 ? optimizedFn : handleChange}
              onBlur={handleBlur}
              placeholder={
                field?.title === "Phone Number"
                  ? "Mobile Number (10 digit only)"
                  : field?.title
              }
            />
          ) : type === "textarea" ? (
            <Form.Control
              as="textarea"
              name={field?._id}
              value={values[field?._id]}
              disabled={searchParamsObj[field?.title]}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={field?.title}
            />
          ) : (
            <Form.Control
              type={type || "text"}
              name={field?._id}
              value={values[field?._id]}
              disabled={searchParamsObj[field?.title]}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={field?.title}
              min={type === "number" ? 0 : undefined}
            />
          )}
          {errors[field?._id] && touched[field?._id] && (
            <div className="errorMessage">{errors[field?._id]}</div>
          )}
        </Form.Group>
      ) : null}
    </>
  );
}

export default TextField;
