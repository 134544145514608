import React from "react";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import faqCss from "./Faqs.module.scss";
const Faqs = () => {
  return (
    <div>
      <div className="faqSection">
        <div className={faqCss.FaqSectionMain}>
          <Container>
            <h1>Frequently Asked Questions: For Online Registration Process</h1>

            <div className={faqCss.FaqContent}>
              <Accordion defaultActiveKey="0" alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className={faqCss.First}>
                    Registration Related Inquiry
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="innerFaq">
                      <Accordion defaultActiveKey="1" alwaysOpen>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header className={faqCss.First}>
                            1. How do I know that my application is registered?
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>
                              Successful Registration is indicated by the Page
                              displayed after clicking the Register Button
                              indicating that a verification Email has been
                              triggered to your registered Email ID, you need to
                              click on the verification Email and you’ll receive
                              a credential mail post that.
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          className={faqCss.AccordianBorder}
                          eventKey="2"
                        >
                          <Accordion.Header>
                            2. I did not receive the email intimation for
                            registration of my application?
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>
                              Delivery of these Emails and SMS purely depends
                              upon the correctness of E-Mail ID and Mobile
                              Number provided, Policy of your E - Mail Service
                              Provider to accept and Divert such emails under
                              Bulk or Spam Mails or bounce back such mails
                              without accepting it due to heavy number of emails
                              generated by our Applications. In case of
                              Govt./official /company Email Ids , delivery is
                              controlled by your Company Policies. You may raise
                              a query as well in this case
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          className={faqCss.AccordianBorder}
                          eventKey="3"
                        >
                          <Accordion.Header>
                            3. I have forgotten my XAT password, could you help!
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>
                            Navigate to website, click on "Login" and then "Forgot Password", follow the steps to change the password.
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={faqCss.AccordianBorder} eventKey="4">
                  <Accordion.Header>Test Related Inquiry</Accordion.Header>
                  <Accordion.Body>
                  <div className="innerFaq">
                      <Accordion defaultActiveKey="11">
                        <Accordion.Item eventKey="11">
                          <Accordion.Header className={faqCss.First}>
                            1. How do I know that my application is registered?
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>
                              Successful Registration is indicated by the Page
                              displayed after clicking the Register Button
                              indicating that a verification Email has been
                              triggered to your registered Email ID, you need to
                              click on the verification Email and you’ll receive
                              a credential mail post that.
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          className={faqCss.AccordianBorder}
                          eventKey="22"
                        >
                          <Accordion.Header>
                            2. I did not receive the email intimation for
                            registration of my application?
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>
                              Delivery of these Emails and SMS purely depends
                              upon the correctness of E-Mail ID and Mobile
                              Number provided, Policy of your E - Mail Service
                              Provider to accept and Divert such emails under
                              Bulk or Spam Mails or bounce back such mails
                              without accepting it due to heavy number of emails
                              generated by our Applications. In case of
                              Govt./official /company Email Ids , delivery is
                              controlled by your Company Policies. You may raise
                              a query as well in this case
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          className={faqCss.AccordianBorder}
                          eventKey="33"
                        >
                          <Accordion.Header>
                            3. I have forgotten my XAT password, could you help!
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>
                            Navigate to website, click on "Login" and then "Forgot Password", follow the steps to change the password.
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={faqCss.AccordianBorder} eventKey="5">
                  <Accordion.Header>
                    Application Related Inquiry
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Successful Registration is indicated by the Page displayed
                      after clicking the Register Button indicating that a
                      verification Email has been triggered to your registered
                      Email ID, you need to click on the verification Email and
                      you’ll receive a credential mail post that.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={faqCss.AccordianBorder} eventKey="6">
                  <Accordion.Header>
                    Eligibility Related Inquiry
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Successful Registration is indicated by the Page displayed
                      after clicking the Register Button indicating that a
                      verification Email has been triggered to your registered
                      Email ID, you need to click on the verification Email and
                      you’ll receive a credential mail post that.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={faqCss.AccordianBorder} eventKey="7">
                  <Accordion.Header>Programme Related Inquiry</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Successful Registration is indicated by the Page displayed
                      after clicking the Register Button indicating that a
                      verification Email has been triggered to your registered
                      Email ID, you need to click on the verification Email and
                      you’ll receive a credential mail post that.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={faqCss.AccordianBorder} eventKey="8">
                  <Accordion.Header>Other Inquiries</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Successful Registration is indicated by the Page displayed
                      after clicking the Register Button indicating that a
                      verification Email has been triggered to your registered
                      Email ID, you need to click on the verification Email and
                      you’ll receive a credential mail post that.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
