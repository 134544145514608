import React from "react";
import { Container } from "react-bootstrap";
import footerCss from "./Footer.module.scss";
const Footer = () => {
  return (
    <div>
      <div className={footerCss.FooterMain}>
        <Container>
          <div className={footerCss.FooterSec}>
            <div className={footerCss.AddressSec}>
              <div className={footerCss.LogoFooter}>
                <img src="/assets/images/main-logo.webp" alt="" />
              </div>
              <div className={footerCss.Text}>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text
                </p>
              </div>
              <div className={footerCss.SocialSec}>
                <div className={footerCss.LeftHeading}>
                  <h3>FOLLOW US</h3>
                </div>
                <div className={footerCss.RightSocial}>
                  <ul>
                    <li>
                    <img src="/assets/images/face-book-icon.svg" alt="" />
                    </li>
                    <li>
                    <img src="/assets/images/insta-icon.svg" alt="" />
                    </li>
                    <li>
                     
                      <img src="/assets/images/twitter-icon.svg" alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <span></span>
            <div className={footerCss.ExmSec}>
              <h3>EXAM RESOURCE</h3>
              <div className={footerCss.SubText}>
                <p>2020</p>
                <p>2019</p>
                <p>2018</p>
                <p>2017</p>
                <p>2016</p>
              </div>
            </div>
            <span></span>
            <div className={footerCss.ContactSec}>
              <h3>CONTACTS</h3>
              <p className={footerCss.BulletTextA}>
                XLRI Xavier School of Management, C. H. Area (East), Jamshedpur
                - 831001, Jharkhand, India
              </p>
              <p className={footerCss.BulletTextB}>
                For Admit-card related queries kindly contact Help Desk: Toll
                Number : 022-61087580 Email: xat2022@xlri.ac.in
              </p>
              <p className={footerCss.BulletTextC}>
                +91-657 665 3203 / +91-6576653204
              </p>
              <p className={footerCss.BulletTextD}>+91-6572227814</p>
            </div>
          </div>
          <div className={footerCss.CopyRight}>
            <p>Copyright © 2022 All Rights Reserved by XLRI.</p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
