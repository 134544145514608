import React from "react";
import { Container } from "react-bootstrap";
import RegCss from "./RegistrationDetails.module.scss";
const RegistrationDetails = () => {
  return (
    <div>
      <div className={RegCss.RegistrationMain}>
        <div className={RegCss.TopSection}>
          <div className={RegCss.InnerContent}>
            <h1>How To Apply?</h1>
            <img src="/assets/images/process-img.webp" alt="" />
            <div className={RegCss.NewFlexParagraph}>

              <p >Register Yourself</p>
              <p className={RegCss.SpaceLeftA}>Verify Email ID</p>
              <p className={RegCss.SpaceLeftB}>Fill XAT Application Form Online</p>
              <p className={RegCss.SpaceLeftC}>Upload Required Documents</p>
              <p className={RegCss.SpaceLeftD}>Pay Application Fee</p>
              <p className={RegCss.SpaceLeftE}>Submit Your Application Form</p>
            </div>
          </div>
        </div>
        <Container>
          <div className={RegCss.TextInsideContent}>
            <h2>XAT 2023 REGISTRATION PROCESS</h2>
            <p className={RegCss.TextPoints}>
              {" "}
              In case the candidate is unable to complete the application form
              in one go, he / she can save the data already entered by choosing
              “SAVE AND NEXT” tab. Prior to submission of the online application
              candidates are advised to use the “SAVE AND NEXT” facility to
              verify the details in the online application form and modify the
              same if required. Visually Impaired candidates should fill the
              application form carefully and verify the details to ensure that
              the same are correct prior to final submission.
            </p>
            <p className={RegCss.TextPoints}>
              Candidates are advised to carefully fill and verify the details
              filled in the online application themselves as no change will be
              possible/ entertained after clicking the FINAL SUBMIT BUTTON.
            </p>
            <p className={RegCss.TextPoints}>
              Name of the candidate or his /her Father’s name/ Mother’s name
              etc. should be spelt correctly in the application as it appears in
              the Certificates/ Mark sheets/Identity proof. Any
              change/alteration found may disqualify the candidature.
            </p>
            <p className={RegCss.TextPoints}>
              Candidates can proceed to Scan and upload Photo & Signature as per
              the specifications given in the Guidelines
            </p>
            <p className={RegCss.TextPoints}>
              Candidates can proceed to fill Academic and Work experience
              details of the Application Form.
            </p>
            <p className={RegCss.TextPoints}>
              Click on the Preview Tab to preview and verify the entire
              application form before FINAL SUBMIT.
            </p>
            <p className={RegCss.TextPoints}>
              Modify details, if required, and click on “SUBMIT” ONLY after
              verifying and ensuring that the photograph, signature uploaded and
              other details filled by you are correct. ‘Applicant details can
              not be edited once the application form is submitted after
              payment.’
            </p>
            <p className={RegCss.TextPoints}>
              Select XLRI programme as per your preference if you want to study
              in XLRI. (Through XAT or Through GMAT)
            </p>
            <p className={RegCss.TextPoints}>
              Select the XAT Test City preference. Candidates can choose 2
              preferred test cities while filling XAT Application form.
              Candidates are advised to choose test city closer to their city of
              residence. However, the final authority to allot the TEST CITY
              rests with authority. (You can edit the test city preference only
              once before November 30, 2021.)
            </p>
            <p className={RegCss.TextPoints}>
              Click on the “Payment” Tab and Proceed for payment.
            </p>

            <p className={RegCss.TextPoints}>Click on the “Submit” button.</p>

            <h2>PAYMENT OF FEES</h2>
            <p className={RegCss.TextPoints}>
              XAT Registration Fee (without late fee) – Rs. 1800/-
            </p>
            <p className={RegCss.TextPoints}>
              Candidates interested in XLRI programmes need to pay an additional
              fee of Rs. 300/- each.
            </p>
            <p className={RegCss.TextPoints}>
              Indian candidates can apply through GMAT/GRE for Ex-PGDM whereas
              an NRI/Foreign national can apply through GMAT for all the
              programmes. Kindly note GRE scores are only valid for EX-PGDM.
            </p>
            <p className={RegCss.TextPoints}>
              Fee for Indian Candidates applying for Ex-PGDM through GMAT/GRE–
              Rs. 2500/-
            </p>
            <p className={RegCss.TextPoints}>
              Fee for NRI/Foreign Candidates applying for one or more programmes
              through GMAT– Rs. 5000/-
            </p>
            <h2>INSTRUCTIONS</h2>
            <p className={RegCss.TextPoints}>
              The application form is integrated with the payment gateway and
              the payment process can be completed by following the
              instructions.
            </p>
            <p className={RegCss.TextPoints}>
              The payment can be made by using Debit Cards (RuPay / Visa /
              Master Card / Maestro), Credit Cards, Internet Banking, IMPS, Cash
              Cards/ Mobile Wallets.
            </p>
            <p className={RegCss.TextPoints}>
              After submitting your payment information, PLEASE WAIT FOR THE
              INTIMATION FROM THE SERVER. DO NOT PRESS BACK OR REFRESH BUTTON IN
              ORDER TO AVOID DOUBLE PAYMENT
            </p>
            <p className={RegCss.TextPoints}>
              On successful completion of the transaction, an e-Receipt will be
              generated. Non-generation of ‘E-Receipt’ indicates PAYMENT
              FAILURE. On failure of payment, Candidates are advised to login
              again using their registered Email ID and Password and repeat the
              process of payment.
            </p>
            <p className={RegCss.TextPoints}>
              Candidates are required to take a printout of the e-Receipt and
              online Application Form. Please note that if the same cannot be
              generated, online transaction may not have been successful.
            </p>
            <p className={RegCss.TextPoints}>
              For Card charges are listed in Indian Rupee. If you use a
              non-Indian credit card, your bank will convert to your local
              currency based on prevailing exchange rates.
            </p>
            <h6>
              There is facility to download/print application form containing
              fee details after payment of fees. Before applying online a
              candidate will be required to have a scanned (digital) image of
              his / her photograph and signature as per the specifications given
              below.
            </h6>
            <p className={RegCss.TextColorPoint}>
            IN CASE THE FACE IN THE PHOTOGRAPH OR SIGNATURE IS UNCLEAR, THE APPLICATION MAY BE REJECTED.
            </p>
            <p className={RegCss.TextColorPoint}>
            CANDIDATE MAY EDIT THE APPLICATION AND RE-UPLOAD THE PHOTOGRAPH/SIGNATURE IN SUCH CASE.
            </p>
            <h2>PHOTOGRAPH IMAGE</h2>
            <p className={RegCss.TextPoints}>
              Photograph must be a recent passport size colour picture.
            </p>
            <p className={RegCss.TextPoints}>
              The picture should be in colour, against a light-coloured,
              preferably white, background.
            </p>
            <p className={RegCss.TextPoints}>
              Look straight at the camera with a relaxed face.
            </p>
            <p className={RegCss.TextPoints}>
              If the picture is taken on a sunny day, have the sun behind you,
              or place yourself in the shade, so that you are not squinting and
              there are no harsh shadows.
            </p>
            <p className={RegCss.TextPoints}>
              If you have to use flash, ensure there’s no “red-eye”.
            </p>
            <p className={RegCss.TextPoints}>
              If you wear glasses make sure that there are no reflections and
              your eyes can be clearly seen.
            </p>
            <p className={RegCss.TextPoints}>
              Caps, hats and dark glasses are not acceptable. Religious headwear
              is allowed but it must not cover your face.
            </p>
            <p className={RegCss.TextPoints}>
              Ensure that the size of the scanned image should be between
              20KB-50KB. If the size of the file is more than 50KB, then adjust
              the settings of the scanner such as the DPI resolution, no. of
              colours etc., during the process of scanning.
            </p>

            <h2>SIGNATURE IMAGE</h2>
            <p className={RegCss.TextPoints}>
              The applicant has to sign on white paper with Black Ink pen.
            </p>
            <p className={RegCss.TextPoints}>
              The signature must be signed only by the applicant and not by any
              other person.
            </p>
            <p className={RegCss.TextPoints}>
              The Signature will be used to put on the ADMIT CARD and wherever
              necessary.
            </p>
            <p className={RegCss.TextPoints}>
              The Applicant’s signature obtained on the ADMIT CARD and
              attendance sheet at the time of the examination should match the
              uploaded signature. In case of mismatch, the candidate may be
              disqualified.
            </p>
            <p className={RegCss.TextPoints}>
              Ensure that the size of the scanned image should be between 20KB –
              50KB.
            </p>

            <h2>SCANNING THE PHOTOGRAPH & SIGNATURE</h2>
            <p className={RegCss.TextPoints}>
              Set the scanner resolution to a minimum of 200 dpi (dots per
              inch).
            </p>
            <p className={RegCss.TextPoints}>Set Colour to True Colour.</p>
            <p className={RegCss.TextPoints}>File Size as specified above.</p>
            <p className={RegCss.TextPoints}>
              Crop the image in the scanner to the edge of the
              photograph/signature, then use the upload editor to crop the image
              to the final size (as specified above).
            </p>
            <p className={RegCss.TextPoints}>
              The image file should be JPG or JPEG format. An example file name
              is: image01.jpg or image01.jpeg Image dimensions can be checked by
              listing the folder files or moving the mouse over the file image
              icon. Candidates using MS Windows/MS Office can easily obtain
              photo and signature in .jpeg format not exceeding 50KB by using MS
              Paint or MS Office Picture Manager. Scanned photograph and
              signature in any format can be saved in .jpg format by using ‘Save
              As’ option in the File menu and size can be reduced below 50KB
              (photograph & signature) by using crop and then resize option.
            </p>
            <h6 className={RegCss.TextPointsBold}>
              If the file size and format are not as prescribed, an error
              message will be displayed. While filling in the Online Application
              Form the candidate will be provided with a link to upload his
              photograph and signature. Procedure for Uploading the Photograph
              and Signature
            </h6>
            <p className={RegCss.TextPoints}>
              There will be two separate links for uploading Photograph and
              Signature.
            </p>
            <p className={RegCss.TextPoints}>
              Click on the respective link “Upload Photograph / Signature”.
            </p>
            <p className={RegCss.TextPoints}>
              Browse & Select the location where the Scanned Photo/ Signature
              file has been saved.
            </p>
            <p className={RegCss.TextPoints}>
              Select the file by clicking on it.
            </p>
            <p className={RegCss.TextPoints}>Click on the “Upload” button.</p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default RegistrationDetails;
