import { useCallback, useEffect } from "react";
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";

export default function RazorpayComponent({ data, setData }) {
    const [Razorpay, isLoaded] = useRazorpay();

  const handlePayment = useCallback(() => {
    const options = {
      key: data?.key,
      amount: data?.amount.toString(),
      currency: "INR",
      name: "XLRI JAMSHEDPUR",
      //   description: "Test Transaction",
      //   image: "https://example.com/your_logo",
      order_id: data?.id,
      handler: (res) => {
        localStorage.clear()
          window.open("https://xlri.ac.in/thank-you","_self")
        
      },
      modal:{
        ondismiss:async function(e){
          console.log("sdsaffF  WEFWEFBU")
          localStorage.setItem('razorpay-number',data?.phone)
          window.location.reload()
        }
      },
      prefill: {
        name: data?.name,
        email: data?.email,
        contact: data?.phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
        name: data?.name,
        email: data?.email,
        paymentFor:`Dynamic form - ${data?.formId}`,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  useEffect(() => {
    if (isLoaded) {
      handlePayment();
    }
    return () => {
      setData({ show: false, data: null });
    };
  }, [isLoaded]);

  return <></>;
}
