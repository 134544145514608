import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./App.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import LandingPage from "./components/LandingPage";
import { directionalLink } from "./apollo";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { REACT_APP_GRAPHQL_PG } from "./helpers/constants";
import { createUser } from "./redux/auth";
import { getToken } from "./helpers/utils";
import RegistrationDetails from "./components/RegistrationDetails/RegistrationDetails";
import Toppers from "./components/Toppers/Toppers";
import AssociateInstitutes from "./components/AssociateInstitutes/AssociateInstitutes";
import Faqs from "./components/Faqs/Faqs";
import Gallery from "./components/Gallery/Gallery";
import Contact from "./components/Contact/Contact";
import Prospectus from "./components/Prospectus/Prospectus";
import { useEffect } from "react";
import GmatGre from "./components/GmatGre/GmatGre";
import TabGallery from "./components/Gallery/TabGallery";
import { ToastContainer } from "react-toastify";
import Register from "./components/Register";
import LandingPageProgram from "./components/LandingPageProgram/LandingPageProgram";
import PublicFormComponent from "./components/Dynamic-form/index";

function App(props) {
  const dispatch = useDispatch();
  const tokenResp = getToken();
  const authToken = useSelector((state) => state?.auth?.token);
  const authId = useSelector((state) => state?.auth?.uid);

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      directionalLink(tokenResp?.token, tokenResp?.uid),
    ]),
  });
  useEffect(() => {
    if (tokenResp) {
      const token = tokenResp?.token;
      const uid = tokenResp?.uid;
      dispatch(createUser({ token, uid }));
    }
  }, [dispatch]);
  return (
    <ApolloProvider client={apolloClient}>
      <ToastContainer />
      <Router>
        {/* <Header /> */}
        <Routes>
          <Route index path="/" element={<Register />} />
          <Route path="/landing" element={<LandingPage/>} />
          <Route path="/landing-page" element={<LandingPageProgram/>} />
          <Route path="/public-form" element={<PublicFormComponent/>}/>
          <Route
            path="/registration-process"
            element={<RegistrationDetails />}
          />
          <Route
            path="/associate-institutes"
            element={<AssociateInstitutes />}
          />
          <Route path="/Gmat-Gre" element={<GmatGre />} />
          <Route path="/toppers-tip" element={<Toppers />} />
          <Route path="/faqs" element={<Faqs />} />
          {/* <Route  path="/" element={<Gallery/>} /> */}
          <Route path="/gallery" element={<TabGallery />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/xat-associate-membership" element={<Prospectus />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </ApolloProvider>
  );
}

export default App;
