import React from "react";
import { LIVE_URL, SECRET } from "../helpers/constants";
import { Modal } from "react-bootstrap";
import loginCss from "./LoginStyle.module.scss";
import { useSelector } from "react-redux";
import AES from "crypto-js/aes";

function MessageForLogin(props) {
  const uid = useSelector((state) => state.auth.uid);

  const handleEncrypt = (uid) => {
    const expirationTime = Date.now() + 60 * 1000;
    const dataToEncrypt = { uid: uid, exp: expirationTime };

    try {
      const encryptedText = AES.encrypt(
        JSON.stringify(dataToEncrypt),
        SECRET
      );

      const encrypted = encodeURIComponent(encryptedText.toString());
      const url = new URL(LIVE_URL);
      url.searchParams.set("auth", encrypted);
      window.open(url.toString());
      props.onHide();
    } catch (error) {
      console.log("the error in encryptedText", error);
      return null;
    }
  };

  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="commonPopupmainSecCommon">
          <div>
            <span className="close-icon" onClick={props.onHide}>
              <img src="/assets/images/close.svg" alt="Close" />
            </span>
          </div>
          <div className={loginCss.CommonWidth}>
            <p className="text-center">
              Thank you for verifying your account. Your account has been
              created successfully. Please log in here to fill out the form
            </p>
            <div className={loginCss.loginMessage}>
              <button onClick={() => handleEncrypt(props.id || uid)}>
                Login
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MessageForLogin;
