import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";

const uploadToFirebase = (file, folder,fileName) => {
  return new Promise((resolve, reject) => {
    const fileRef = ref(storage, `${folder}/${fileName}`);
    uploadBytes(fileRef, file).then(() => {
      getDownloadURL(fileRef).then((url) => {
        resolve(url);
      });
    });
  });
};

export default uploadToFirebase;
