import React from "react";
import Spinner from "react-bootstrap/Spinner";
// $primary-color: #1b4e9b;

const styles = {
  fullLoader: {
    wrapper: {
      zIndex: 1,
      position: "fixed",
      left: 0,
      top: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#fffff",
    },
  },
};

export const FullLoader = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={styles.fullLoader.wrapper}
  >
    <Spinner animation="border" variant="primary" />
  </div>
);
