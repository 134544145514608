import React from "react";
import { Container } from "react-bootstrap";
import GmatGreCss from "./GmatGre.module.scss";
const GmatGre = () => {
  return (
    <div>
      <div className={GmatGreCss.GmatGreMain}>
        <Container>
          <div className={GmatGreCss.TextInsideGre}>
            <h5>
              Online Registration through GMAT for NRI students and Ex-PGDM
              (General Management) Program candidates
            </h5>
            <h6>
              XLRI announces Admission for the following Programmes through GMAT
            </h6>
            <p className={GmatGreCss.TextPoints}>PGDM (Business Management).</p>
            <div className={GmatGreCss.SubText}>
              <p className={GmatGreCss.TextColorPoint}>BM- Jamshedpur</p>
              <p className={GmatGreCss.TextColorPoint}>BM Delhi - NCR</p>
            </div>
            <p className={GmatGreCss.TextPoints}>
              PGDM (Human Resource Management)
            </p>
            <p className={GmatGreCss.TextPoints}>
              Ex-PGDM (General Management)
            </p>
            <p className={GmatGreCss.TextPoints}>
              Fellow Programme in Management
            </p>
            <h5>XLRI GMAT CODE - WW2-8N-93</h5>
            <h5>XLRI GRE CODE - 5030</h5>
            <p className={GmatGreCss.TextPointsBold}>
              The following categories of candidates can also apply through GMAT
              score obtained during December 01, 2016 and December 31, 2021 for
              the following XLRI programmes. GRE scores are acceptable only for
              EX-PGDM.
            </p>
            <p className={GmatGreCss.TextPoints}>
              NRIs interested in applying to BM, HRM and Ex-PGDM.
            </p>
            <p className={GmatGreCss.TextPoints}>
              Indians interested in applying to Ex-PGDM.
            </p>
            <p className={GmatGreCss.TextPoints}>
              Candidate interested through GMAT should also register for XAT and
              follow the instructions before registration through GMAT.
            </p>
            <p className={GmatGreCss.TextPoints}>
              NRI / Foreign Students Definition as per Regulation for admission
              of NRI/Foreign Nationality/Person of Indian Origin, AICTE letter
              dated 24 April 2002 is as follows:
            </p>

            <p className="mt-3 mb-3">
              <b>NRI : </b>
              NRI in this context means Non-Resident Indian as defined in Income
              Tax Act, 1961 with the following clarifications: An individual is
              Non-Resident when he is not a resident or who is not ordinarily
              resident . A person is treated as not ordinarily resident when any
              of the following conditions is satisfied:
            </p>
            <p className={GmatGreCss.TextPoints}>
              If he/she has not been resident in India in nine out of ten
              preceding years; or
            </p>
            <p className={GmatGreCss.TextPoints}>
              If he/she has not been in India for a period of 730 days or more
              during the preceding seven years.
            </p>
            <p>
              <b> Persons of Indian Origin : </b> Persons of Indian Origin (PIO)
              in this context means a person having foreign citizenship (except
              Pakistan and Bangladesh) without NRI status, but who holds a
              Foreign Passport at the time of sending application, consideration
              for admission and during the period of his study and he/she or
              anyone/ both of his/her parents or anyone / both of his /her
              grandparents is (or was) / are (or were) citizen (s) of India by
              virtue of the provisions of the Constitution of India or Section 2
              (b) of Citizenship Act, 1955 (Act No. 57 of 1955).
            </p>
            <p>
              <b>Foreign National : </b> Foreign National (FN) in this context
              means an individual having citizenship of a foreign country
              (citizens of all countries other than India) and not having the
              status of NRI and/or Persons of Indian Origin (PIO).
            </p>
            <p>
            <b> Eligibility : </b> Candidates must hold a recognized bachelor’s degree
              of minimum three years duration in any discipline with at least 5
              years of managerial/supervisory experience by March 31, 2023. PGDM
              (Ex-PGDM) candidate may choose to apply through XAT 2023 or GMAT
              score (taken during December 1, 2016 to December 31, 2021).
            </p>
            <p><b>Note:</b></p>
            <p>All Indian candidates must appear for XAT 2023. However, NRI and foreign candidates may apply through GMAT score</p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default GmatGre;
