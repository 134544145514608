import React from "react";
import loginCss from "./LoginStyle.module.scss";
import { Modal } from "react-bootstrap";
const MembershipPopup = (props) => {
  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="commonPopupmainSecCommon">
          <div>
            <span className="close-icon" onClick={props.onHide}>
              <img src="/assets/images/close.svg" alt="Close" />
            </span>
          </div>
          <div className={loginCss.CommonWidth}>
            <div className="col-md-12">
              <h3>Registration of XAT Associate Institutes for XAT 2023</h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="Name*"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="emailid"
                    id="emailid"
                    placeholder="Email ID*"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Mobile number*"
                  />
                </div>
              </div>
              <div className={loginCss.Onediv}>
                <div className={loginCss.thatText}>
                  <h6>CFN</h6>
                  <img src="/assets/images/refresh.svg" alt="" />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Text as Shown"
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label mb-3"
                    for="flexCheckDefault"
                  >
                    I agree to receive information regarding my registration for
                    XAT Associate Membership *
                  </label>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group">
                  <button className="continuesBtnMain mt-2">REGISTER</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MembershipPopup;
