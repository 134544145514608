import { Form } from "react-bootstrap";
import uploadToFirebase from "../../../helpers/FirebaseUpload";
import { useState, useEffect } from "react";

function MultipleFileField({
  field,
  values,
  touched,
  handleBlur,
  setFieldValue,
  errors,
  searchParamsObj,
}) {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    if (values[field?._id]) {
      const initialFiles = values[field?._id].split(",");
      setUploadedFiles(initialFiles);
    }
  }, [values[field?._id]]);

  useEffect(() => {
    setFieldValue(field?._id, uploadedFiles.join(","));
  }, [uploadedFiles, setFieldValue, field?._id]);

  async function handleUpload(e) {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      const folder = "DocumentsUnderDynamicForm";
      const uploadedFileUrls = await Promise.all(
        files.map(async (file) => {
          const url = await uploadToFirebase(file, folder, file.name);
          return url;
        })
      );

      const updatedFiles = [...uploadedFiles, ...uploadedFileUrls];
      setUploadedFiles(updatedFiles);
      setFieldValue(field?._id, updatedFiles.join(","));
    }
  }

  const handleDelete = (fileUrl, e) => {
    e.preventDefault();
    const updatedFiles = uploadedFiles.filter((file) => file !== fileUrl);
    setUploadedFiles(updatedFiles);

    setFieldValue(field?._id, updatedFiles.join(","));
  };

  const isBold = field?.highlight;
  return (
    <Form.Group>
      <Form.Label style={isBold ? { fontWeight: "500" } : {}}>
        {field?.title}
      </Form.Label>
      <Form.Control
        type="file"
        name={field?._id}
        multiple
        onChange={handleUpload}
        onBlur={handleBlur}
        disabled={searchParamsObj[field?.title]}
      />

      {uploadedFiles.length > 0 && (
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {uploadedFiles.map((fileUrl, index) => {
            // Extract the file name from the URL and decode it
            const decodedUrl = decodeURIComponent(fileUrl);
            const fileName = decodedUrl.split("/").pop().split("?")[0];

            return (
              <div
                key={index}
                style={{
                  border: "1px solid #ccc",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "5px" }}>📄</span> {/* File icon */}
                <a
                  href={fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    marginRight: "10px",
                  }}
                >
                  {fileName} {/* Display only the file name */}
                </a>
                <button
                  type="button" // Prevent form submission
                  onClick={(event) => handleDelete(fileUrl, event)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "red",
                  }}
                >
                  🗑️
                </button>{" "}
                {/* Delete icon */}
              </div>
            );
          })}
        </div>
      )}

      {touched[field?._id] && errors[field?._id] && (
        <div className="errorMessage">{errors[field?._id]}</div>
      )}
    </Form.Group>
  );
}

export default MultipleFileField;
