import { onError } from "@apollo/client/link/error";
import { ApolloLink, HttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";

export const directionalLink = (token, uid) =>
  ApolloLink.split(
    (operation) => operation.getContext().clientName === "admin",
    new createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_ADMIN,
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
            uuid: uid,
            "Access-Control-Allow-Origin": "*",
            "Cross-Origin-Embedder-Policy": "require-corp",
            "Cross-Origin-Opener-Policy": "same-origin",
          }
        : {},
    }),

    new ApolloLink.split(
      (operation) => operation.getContext().clientName === "hrms",
      new createUploadLink({
        uri: process.env.REACT_APP_GRAPHQL_HRMS,
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
              uuid: uid,
              "Access-Control-Allow-Origin": "*",
              "Cross-Origin-Embedder-Policy": "require-corp",
              "Cross-Origin-Opener-Policy": "same-origin",
            }
          : {},
      }),

      new ApolloLink.split(
        (operation) => operation.getContext().clientName === "admission",
        new createUploadLink({
          uri: process.env.REACT_APP_GRAPHQL_ADMISSION,
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
                uuid: uid,
                "Access-Control-Allow-Origin": "*",
                "Cross-Origin-Embedder-Policy": "require-corp",
                "Cross-Origin-Opener-Policy": "same-origin",
              }
            : {},
        }),
        new ApolloLink.split(
          (operation) => operation.getContext().clientName === "cms",
          new createUploadLink({
            uri: process.env.REACT_APP_GRAPHQL_CMS,
            headers: token
              ? {
                  Authorization: `Bearer ${token}`,
                  uuid: uid,
                  "Access-Control-Allow-Origin": "*",
                  "Cross-Origin-Embedder-Policy": "require-corp",
                  "Cross-Origin-Opener-Policy": "same-origin",
                }
              : {},
          }),
          new ApolloLink.split(
            (operation) => operation.getContext().clientName === "caseCenter",
            new createUploadLink({
              uri: process.env.REACT_APP_GRAPHQL_CASE_CENTER,
              headers: token
                ? {
                    Authorization: `Bearer ${token}`,
                    uuid: uid,
                    "Access-Control-Allow-Origin": "*",
                    "Cross-Origin-Embedder-Policy": "require-corp",
                    "Cross-Origin-Opener-Policy": "same-origin",
                  }
                : {},
            }),
            new ApolloLink.split(
              (operation) => operation.getContext().clientName === "alumni",
              new createUploadLink({
                uri: process.env.REACT_APP_GRAPHQL_ALUMNI,
                headers: token
                  ? {
                      Authorization: `Bearer ${token}`,
                      uuid: uid,
                      "Access-Control-Allow-Origin": "*",
                      "Cross-Origin-Embedder-Policy": "require-corp",
                      "Cross-Origin-Opener-Policy": "same-origin",
                    }
                  : {},
              }),
              new ApolloLink.split(
                (operation) => operation.getContext().clientName === "adsa",
                new createUploadLink({
                  uri: process.env.REACT_APP_GRAPHQL_ADSA,
                  headers: token
                    ? {
                        Authorization: `Bearer ${token}`,
                        uuid: uid,
                        "Access-Control-Allow-Origin": "*",
                        "Cross-Origin-Embedder-Policy": "require-corp",
                        "Cross-Origin-Opener-Policy": "same-origin",
                      }
                    : {},
                }),
                new ApolloLink.split(
                  (operation) =>
                    operation.getContext().clientName === "utility",
                  new createUploadLink({
                    uri: process.env.REACT_APP_GRAPHQL_UTILITY,
                    headers: token
                      ? {
                          Authorization: `Bearer ${token}`,
                          uuid: uid,
                          "Access-Control-Allow-Origin": "*",
                          "Cross-Origin-Embedder-Policy": "require-corp",
                          "Cross-Origin-Opener-Policy": "same-origin",
                        }
                      : {},
                  }),
                  new createUploadLink({
                    uri: process.env.REACT_APP_GRAPHQL_LMS,
                    headers: token
                      ? {
                          Authorization: `Bearer ${process.env.REACT_APP_AUTHTOKEN}`,
                          uuid: uid,
                          "Access-Control-Allow-Origin": "*",
                          "Cross-Origin-Embedder-Policy": "require-corp",
                          "Cross-Origin-Opener-Policy": "same-origin",
                        }
                      : {},
                  })
                )
              )
            )
          )
        )
      )
    )
  );

// export const httpLinkAdmin = (token,uid) =>
//   createUploadLink({
//     uri: REACT_APP_GRAPHQL_ADMIN,
//     headers: token ? { 'Authorization': `Bearer ${token}`, 'uuid' : uid, "Access-Control-Allow-Origin" : "*",} : {},
//   });
//   export const httpLinkAdmission = (token,uid) =>
//   createUploadLink({
//     uri: REACT_APP_GRAPHQL_ADMISSION,
//     headers: token ? { 'Authorization': `Bearer ${token}`, 'uuid' : uid, "Access-Control-Allow-Origin" : "*",} : {},
//   });

export const errorLink = (onForbidden) =>
  onError(({ graphQLErrors }) => {
    if (graphQLErrors?.length) {
      graphQLErrors.forEach(({ message, extensions }) => {
        if (extensions?.code === "UNAUTHENTICATED") onForbidden(message);
      });
    }
  });
